import React, { useEffect, useMemo, useState } from 'react'
import {
  FaCheck as FinishIcon,
  FaBalanceScale as OperationIcon,
  FaBars as QueueIcon,
  FaRoad as TransitIcon
} from 'react-icons/fa'
import {
  BsCircleFill as OkIcon,
  BsFillTriangleFill as FailIcon,
} from "react-icons/bs"
import {
  MdCancel as CancelIcon,
  MdDelete as DeleteIcon,
} from 'react-icons/md'

import { PortariaSteps } from './PortariasSteps'
import { Frame, IconButton, Table } from '../../../../../c'
import { Icon } from '../../../../../c/Icon'
import { Details } from '../../../../../c/Details'
import { Badge } from '../../../../../c/Badge'
import { formatMoney, formatDate } from '../../../../../f'
import { getDetails } from '../../useCases/getDetails'

const lists = [
'crlvs',
'dcos',
'docs_aux',
'docs_rf',
'mdfes',
'nfps',
'steps',
'tickets_pesagem',
'valicaoes',
]

function fieldDate(data, key) {
  if (!data || typeof data.ID_FLD !== 'string') {
    return data[key]; 
  }
  const options = {default:'', input:'YYYY-MM-DD', output:'DD/MM/YYYY hh:mm:ss' }
  const dateFields = new Set(['Data entrada', 'Data saída', 'Data chegada']);
  const trimmedField = data.ID_FLD.trim();
  if (dateFields.has(trimmedField)) {
      return formatDate(data[key], options);
  }
  return data[key];
}


const fetchDetails = async (api, record) => {
  const ID_UOCC = record.ticketId;
  try {
    const results = await Promise.all([
      await api('controllership::detail', { ID_UOCC }),
      await api('controllership::records', { ticket: ID_UOCC }),
    ])

    const [
      { results: detailExtras }, // details response
      { results: { records: [newRecord] } }, // records response
    ] = results;

    return { ...newRecord, ...detailExtras };
  } catch (error) {
    console.error('Error fetching details:', error);
    return null;
  }
};

export const SubRowColumn = ({ portaria: portariaInicial, methods: { api, confirm, onFetchRecords } }) =>  {
  const [portaria, setPortaria] = useState(portariaInicial)

  const loading = lists.every((list) => !portaria[list])

  const details = useMemo(() => getDetails(portaria), [portaria])

  useEffect(() => {
    if (loading) {
      fetchDetails(api, portariaInicial).then((updatedRecord) => {
        if (updatedRecord) {
          setPortaria(updatedRecord);
        }
      });
    }
  }, [api, loading, portariaInicial]);

  if (loading) return <></>

  return (
    <div className="f f-column g1">
      <PortariaSteps steps={[
        // [portaria.steps.AGENDADO, 'Agendado', icons.FaCalendarAlt], // Não apagar
        [portaria.steps.TRANSITO, 'Trânsito', TransitIcon],
        // [portaria.steps.RECEPCIONADO, 'Recepcionado', icons.FaMapMarkerAlt], // Não apagar
        // [portaria.steps.CLASSIFICACAO, 'Em classificação', icons.FaClipboardList], // Não apagar
        [portaria.steps.FILA, 'Em fila', QueueIcon],
        [portaria.steps.OPERACAO, 'Em operação', OperationIcon],
        [portaria.steps.CONCLUIDO, 'Finalizado', FinishIcon],
      ]} />

      <Frame>
        <Details items={details} />
      </Frame>

      <Frame>
      {!!portaria.dcos.length &&
        <Table isSub hasSub={'_SUB'} // dcos
          columns={[
            ['Tipo','_VALIDATION_ICON'],
            ['Data','DT_DCO'],
            ['Número/Série','_NUM_NOTA'],
            ['Chave','NR_NFE'],
            ['Emitente','NM_PES'],
            ['Quantidade','_QT_ORG'],
            ['Valor','_VL_ORG'],
            ['','_ACTION',{},'force-fit'],
          ]}
          data={portaria.dcos.map(dco => {
            const valid = dco.validacoes?.every(v => v.CD_STS === '-1') ?? false
            const block = dco.validacoes?.some(v => v.CD_STS === '-3') ?? false
            const notif = !valid && !block && (dco.validacoes?.some(v => v.CD_STS === '1') ?? false)

            const tpDf = dco.TP_DF === '55' ? 'NFe' : 'CTe';

            return {
              ...dco,
              _VALIDATION_ICON:
                valid
                ?
                <Badge text={tpDf} color={'success'} size={'sm'}/>
                : notif
                ?
                <Badge text={tpDf} color={'warning'} size={'sm'}/>
                : block
                ?
                <Badge text={tpDf} color={'danger'} size={'sm'}/>
                :
                <Badge text={tpDf} color={'success'} size={'sm'}/>,
              _NUM_NOTA: dco.NR_DCO+'/'+dco.CD_DCO,
              _QT_ORG: parseFloat(dco.QT_ORG ?? 0).toLocaleString() + 'Kg',
              _VL_ORG: formatMoney(dco.VL_ORG ?? 0, 2),
              _ACTION: (
                <div className="f g1 center-v">
                  <IconButton
                    icon={DeleteIcon}
                    title="Excluir documento"
                    size={30}
                    onClick={() => {
                      confirm(
                        `Confirma a exclusão do documento ${dco.NR_NFE}?`,
                        async () => {
                          const { status } = await api(
                            'portarias::deleteDCO',
                            { tp: 'dco', id: dco.ID_UOCC, conciergeData: portaria.id }
                          )

                          onFetchRecords()

                          return status
                        }
                      )
                    }}
                  />
                </div>
              ),
              _SUB: !!dco.validacoes &&(
                <Table isSub
                  columns={[
                    ['','_VALIDATION_ICON',{},'force-fit'],
                    ['Regra','DS_VLD'],
                    ['No agendamento','VL_PRT'],
                    ['No XML','VL_XML'],
                  ]}
                  data={dco.validacoes.map(v => ({...v,
                    _VALIDATION_ICON:
                      v.CD_STS === '-1' ? <OkIcon size={16} style={{color: 'var(--success)'}} />
                      : v.CD_STS === '-3' ? <CancelIcon size={16} style={{color: 'var(--danger)'}} />
                      : v.CD_STS === '1' ? <FailIcon size={16} style={{color: 'var(--warning)'}} /> : '',
                  }))}
                />
              )
            }
          })}
        />
      }

      {!!portaria.docs_aux.length &&
        <Table
          isSub // docs_aux
          columns={[
            ['Data','DT_DCT'],
            ['Nome','NM_DCO'],
            ['','_ACTION',{},'force-fit'],
          ]}
          data={portaria.docs_aux}
        />
      }

      {!!portaria.docs_rf.length && (
        <Table
          isSub
          columns={[['Data','DT_DCT'], ['Nome','NM_DCO']]}
          data={portaria.docs_rf} // docs_rf
        />
      )}

      {!!portaria.tickets_pesagem.length &&
        <Table
          isSub
          hasSub={'_SUB'}
          columns={[
            ['Tipo', '_VALIDATION_ICON'],
            ['Nr. Documento', 'ID_UOCC'],
            ['Data Validação', 'DATAOCR'],
            ['Extensão Arquivo', 'TP_EXT'],
          ]}
          data={portaria.tickets_pesagem.map(ticket => {
            const acronym = ticket.DS_TDOC ===  'TICKET / RELATORIO DE DESCARGA' ? 'TD/RD' : 'N/A'

            return {
              _VALIDATION_ICON: !(ticket.CD_IDCO & 1) //verifica se o bitmasking retorna 1 e o inverte para false (semelhante ao BITAND no SQL)
                ?
                <Badge text={acronym} color={'success'} size={'lg'}/>
                :
                <Badge text={acronym} color={'warning'} size={'lg'}/>,
              ID_UOCC: ticket.ID_UOCC,
              DATAOCR: ticket.DATAOCR,
              TP_EXT: ticket.TP_EXT,
              _SUB: !!ticket.validacoes.length &&
                <Table
                  isSub
                  columns={[
                    ['', '_VALIDATION_ICON', {}, 'force-fit'],
                    ['Nome do Campo', 'ID_FLD'],
                    ['Valor', 'VL_FLDO'],
                    ['Valor Portaria', 'VL_FLDP'],
                    ['Assertividade', 'PE_ASST'],
                  ]}
                  data={ticket.validacoes.map((validacao) => ({
                    ...validacao,
                    ID_FLD: validacao.ID_FLD.trim(),
                    VL_FLDO: validacao.ID_FLD.trim() === 'ASSERTIVIDADE' ? `${validacao.VL_FLDO}%`  : fieldDate(validacao, 'VL_FLDO'),
                    VL_FLDP: validacao.ID_FLD.trim() === 'ASSERTIVIDADE' ? `${validacao.VL_FLDP}%` :  fieldDate(validacao, 'VL_FLDP'),
                    PE_ASST: validacao.PE_ASST != null ? `${validacao.PE_ASST}%` : validacao.PE_ASST,
                    _VALIDATION_ICON: validacao.CD_IDCO !== '1'
                        ? <Icon icon={OkIcon} size={16} color="var(--success)" />
                        : <Icon icon={FailIcon} size={16} color="var(--warning)" />,
                  }))}
                />
            }
          })}
        />
      }
      </Frame>
    </div>
  )
}
